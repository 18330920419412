import React, { useContext, useEffect } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { PaymentMethodOption } from './option';
import { Field, TextInput } from '../../form';
import { PaymentMethodContext } from '../payment-methods';
import { useLocale } from '../../../hooks/use-locale';

export function BacsPaymentMethodOption () {
  const { t } = useLocale();
  const recurly = useRecurly();
  const {
    paymentMethod,
    paymentMethodForType,
    setBillingInfoTokenCreator
  } = useContext(PaymentMethodContext);
  const bacsPaymentMethod = paymentMethodForType('bacs');

  useEffect(() => {
    if (paymentMethod?.type !== bacsPaymentMethod?.type) return;
    setBillingInfoTokenCreator(recurly.bankAccount.token);
  }, [paymentMethod]);

  return (
    <PaymentMethodOption title={t('payment-method.bacs.name')} type="bacs">
      <Field
        label={t('payment-method.bacs.sort-code')}
        className="col-span-2"
        name="sort_code"
        required
      >
        <TextInput />
      </Field>
      <Field
        label={t('payment-method.ach.account-number')}
        className="col-span-2"
        name="account_number"
        required
      >
        <TextInput />
      </Field>
      <Field
        label={t('payment-method.ach.account-number-confirmation')}
        className="col-span-2"
        name="account_number_confirmation"
        required
      >
        <TextInput />
      </Field>
      <Field
        label={t('payment-method.ach.name-on-account')}
        className="col-span-2"
        name="name_on_account"
        required
      >
        <TextInput />
      </Field>
      <input type="hidden" data-recurly="type" value="bacs" />
    </PaymentMethodOption>
  );
}
